import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import Vote from '../layout/VotePage'
import flagImage from '../img/socialmedia.png'

class VotePage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  formLoaded() {}

  render() {
    return (
      <PageLayout showBigCircle={false}>
        <MetaSEO
          title="Rank Tiffany Cabán #1 for City Council District 22"
          description="Election day is on June 22nd. Early voting starts on June 12th. Vote and Rank Tiffany Cabán #1"
          keywords={[
            `tiffany cabán`,
            `tiffany caban`,
            `caban for queens`,
            `caban`,
            `cabán`,
            `queens district attorney`,
            `district attorney`,
          ]}
          url="https://www.cabanforqueens.com/vote"
          image={flagImage}
        />
        <Vote {...{ ...this.props.data.page }} />
      </PageLayout>
    )
  }
}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default VotePage

export const VotePageQuery = graphql`
  query votePagePageQuery {
    page: markdownRemark(frontmatter: { uniq: { eq: "vote" } }) {
      html
      frontmatter {
        title
        subtitle
        spiel
      }
    }
  }
`
