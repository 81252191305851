import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { withPrefix } from 'gatsby'
import Helmet from 'react-helmet'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/Footer'
import Header from '../components/HeaderEnglishOnly'

import bgImage from '../img/tlc-issues.jpg'
import rcvImage from '../img/rcv.png'

const VotePage = (props) => (
  <main>
    <Helmet>
      <script
        src={withPrefix('/scripts/toc.js')}
        type="text/javascript"
        defer
      />
    </Helmet>
    <Header className="bg-white" />
    <h1 className="tmp-policy-title">Voting For Tiffany</h1>
    {/* <img src={flagImage} className="flagImage" height="auto" /> */}

    <div className="tmp-public-safety">
      <div className="tmp-public-safety-grid">
        <div className="tmp-public-safety-content">
          <h2>New York Voting Schedule</h2>
          <ul>
            <li>
              Deadline for requesting an absentee ballot online, emailed, or
              faxed: Monday, October 18
            </li>
            <li>
              Deadline for requesting an absentee ballot in person: Monday,
              November 1
            </li>
            <li>Early Voting: Saturday, October 23 – Sunday, October 31</li>
            <li>Election Day: Tuesday, November 2</li>
          </ul>

          <h2>Early Voting</h2>
          <p>
            Early voting will start Saturday October 23rd and run through
            Sunday, October 31st. Early voting is a great way to get the
            experience of voting in person while avoiding the Election Day
            lines.
          </p>

          <div>
            <Button
              size="lg"
              variant="primary"
              href="https://findmypollsite.vote.nyc"
            >
              <strong>Find your early voting site</strong>
            </Button>
          </div>

          <h3>Early Voting Times</h3>
          <ul>
            <li>Saturday, October 23 – 8:00 AM to 5:00 PM</li>
            <li>Sunday, October 24 – 8:00 AM to 5:00 PM</li>
            <li>Monday, October 25 – 7:00 AM to 4:00 PM</li>
            <li>Tuesday, October 26 – 10:00 AM to 8:00 PM</li>
            <li>Wednesday, October 27 – 10:00 AM to 8:00 PM</li>
            <li>Thursday, October 28 – 10:00 AM to 8:00 PM</li>
            <li>Friday, October 29 – 7:00 AM to 4:00 PM</li>
            <li>Saturday, October 30 – 8:00 AM to 5:00 PM</li>
            <li>Sunday, October 31 – 8:00 AM to 4:00 PM</li>
          </ul>

          <h2>Voting on Election Day</h2>
          <p>
            You can vote on election day, November 2nd anytime between 6:00 AM
            and 9:00 PM.
          </p>

          <div>
            <Button
              size="lg"
              variant="primary"
              href="https://findmypollsite.vote.nyc"
            >
              <strong>Find your polling site</strong>
            </Button>
          </div>

          <h2>Voting by Mail</h2>

          <p>
            Due to the COVID-19 pandemic, you can vote from the safety of your
            home through a mail-in (also known as an absentee) ballot. The
            definition of “temporary illness” has been expanded to include risk
            of contracting COVID-19, so please mark it as the reason for
            requesting an absentee ballot. If you would like to request your
            absentee ballot, please do so by October 18th at
            nycabsentee.com/absentee. Remember, you must mail your ballot by
            November 2nd for it to be counted.
          </p>

          <h3>Here are steps to fill out your absentee ballot:</h3>
          <ol>
            <li>
              Open your ballot and unfold it. You will notice that it is
              two-sided. Be sure to locate CITY COUNCIL so you can vote for
              Tiffany in her race.
            </li>
            <li>
              Use a pen or pencil to fill in the circles. Remember to rank
              Tiffany #1! Make sure to fill in the circle completely, and to not
              make check marks or ‘x’s.
            </li>
            <li>
              You will find two envelopes. After you’re done voting, fold your
              ballot and place it in the envelope that says “OFFICIAL ABSENTEE
              BALLOT”.
            </li>
            <li>
              Seal the envelope, flip it over, and be sure to sign and date
              where indicated.
            </li>
            <li>
              Then, place the first envelope into the second envelope which says
              “OFFICIAL ELECTION MAIL.” Finally, place the ballot in your
              mailbox. You do not need a stamp. If you do not follow these
              steps, your ballot may not count.
            </li>
          </ol>
          <p>
            <strong>
              If you do not follow these steps, your ballot may not count.
            </strong>
          </p>
        </div>
      </div>
    </div>

    <Footer className="mt-5" />
  </main>
)

export default VotePage
